import React, { useEffect, useState, useContext } from "react";
import Header from "../component/header/Header";
// import { ReactComponent as MySvgFile } from "./polygon.svg";
import polygon from '../assets/polygon.png'
import "./Assets.css";
import asset from "../assets/asset-bg-1.png";
// import assetProfile from "../assets/asset-profile.png"
import { Link } from "react-router-dom";
import { Alchemy, Network } from "alchemy-sdk";
import { ThreeDots } from "react-loader-spinner";
import { Context } from "../Context";
import ProductHeader from "../component/productHeader/ProductHeader";
import Footer from "../component/Footer/Footer"
import { useLocation, useNavigationType } from "react-router-dom";
import { MdDone } from 'react-icons/md'
import { CiEdit } from 'react-icons/ci'
import { FaRegEdit } from 'react-icons/fa'
import { BeatLoader, PuffLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
function Assets() {


  var itemsAll = ["Chrome_Heart", "Flora_Flamboyance", "Puffy_Crossroads", "Oyster_Spell", "Vibrance_Splash", "Rufflanza", "Star_Cloak", "Celestial_Dream", "Pop_Kiss", "Dazzling_Devil", "Comic_Boom", "Human_Masquerade"]

  // const assetProfile = "https://storage.googleapis.com/opensea-static/opensea-profile/10.png"

  const [assets, setAssets] = useState([]);
  const [assetProfile, setAssetProfile] = useState("https://storage.googleapis.com/opensea-static/opensea-profile/10.png")
  const location = useLocation();
  const navType = useNavigationType();
  const { walletAddress } = useContext(Context);
  const [pending, setPending] = useState(false);
  let itemSold = {}
  const fun = async () => {
    await axios.post('https://api.metadrip.xrcouture.com/user/getuserdata', { address: walletAddress }).then(res => {
      setAssetProfile(res.data.logo)
      if (res.data.userName) {
        setName(res.data.userName)
      }
    }).catch(e => console.log(e))
    var a = [];
    setPending(true);
    const config = {
      apiKey: "g7dUSvAcvJnj2Qf0HOIHlxindWYB88gu",
      network: Network.MATIC_MAINNET,
    };
    const collectionAddress = [
      "0xEDe30DF507576e461cc2cB3AdA75bf9B22dc778d", //phase 1
      "0x99D6C0d1A656a1ee1F345AE6482D0aFD76daF8a5", //phase 2
    ];
    const alchemy = new Alchemy(config);
    alchemy.nft
      .getNftsForOwner(walletAddress, {
        contractAddresses: collectionAddress,
        omitMetadata: false,
      })
      .then((res) => {
        a = res["ownedNfts"];
        setAssets(a);
        console.log(a)
      })
      .catch((e) => {
        // alert(e);
      });
    setTimeout(() => {
      setPending(false);
    }, 1000);
  }
  const [show, setShow] = useState(false)





  const handleSubmit = (e) => {
    setLoader(true)
    axios.post('https://api.metadrip.xrcouture.com/user/updateusername', {
      userName: name,
      address: walletAddress
    }).then(res => {
      setName(res.data.userName)
      setTimeout(() => {
        setShow(!show)
        setLoader(false)
        toast.success("Name Updated Successfully!")
      }, 1500)
      console.log(res)
    }).catch(e => {
      setLoader(false)
      toast.error("Something went wrong, Please Try agian!")
    })

  }
  const handleChange = async (e) => {
    setLoading(true)
    const formdata = new FormData()
    formdata.append("files", e.target.files[0])
    formdata.append("address", walletAddress)
    axios.post('https://api.metadrip.xrcouture.com/user/upload', formdata).then(res => {
      setAssetProfile(res.data.logo)
      toast.success("Profile Updated Successfully!")
      setTimeout(() => {
        setLoading(false)
      }, 1500)
    }).catch(e => {
      setLoading(false)
      toast.error("Something went wrong, Please Try agian!")
    })

  }


  useEffect(() => {
    if (navType !== "POP") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    fun()
  }, [location, walletAddress]);
  const [name, setName] = useState("Unnamed")
  const [loader, setLoader] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <div className="asset-page">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ zIndex: 100000 }}
      />
      <ProductHeader />
      <div className="d-flex justify-content-center row position-relative">
        <img src={asset} alt="asset" className="asset-bg" />
        <div className="bg-overlay"></div>
        <div className="row col-md-6 col-lg-6 col-xl-6 position-absolute container-asset">
          <div className="col-md-6 col-sm-12 d-flex justify-content-lg-end">
            <div className="asset-profile-icon header-logo d-flex align-items-center" style={{
              backgroundImage: `url(${assetProfile})`, backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat",
              height: "200px", width: "200px", borderRadius: "25px"
            }}>
              <input type="file" required className="profile-input position-absolute" onChange={e => handleChange(e)} disabled={loading} />
              <div className={`h-100 w-100 d-flex align-items-center justify-content-center ${!loading ? 'edit-icon-profile' : 'loading-icon-profile'}`}>
                {loading ? <PuffLoader color="green" /> : <FaRegEdit className="" color="white" size={30} />}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="asset-profile-icon asset-profile-text  ml-0" style={{ fontFamily: "Clash Display Bold", color: "#fff" }}>
              {!show && <><div className="d-flex text-cover "><div >{name.length > 0 ? name : ''}</div> <CiEdit size={16} className="edit-icon-disable" onClick={() => setShow(!show)} /> </div></>
              }
              {
                show && (<div class='inputWithButton '><input type="text" className="asset-profile-text" placeholder="Enter your name" value={name} onChange={e => setName(e.target.value)} />
                  {!loader ? <button onClick={() => handleSubmit()}><MdDone /></button> :
                    <BeatLoader size={5} color="white" />}
                </div>)
              }
              <div style={{ fontSize: "16px", marginTop: `${loading ? "15px" : ""} !important` }} className="d-flex align-items-center">
                <img src={polygon} width="25px" style={{ marginRight: "3px" }} color="white" />{walletAddress.substr(0, 6) + "....." + walletAddress.slice(38)}</div>
            </div>
          </div>
        </div>
        <div className="bg-timer col-md-6">
          <div className="timer-header text-white">Claim the MVFW23 Airdrop</div>
          <div className="timer-subtitle text-white">Claim ends in:</div>
          <div className="timer-cont"></div>
          <div className="timer-btn header-button">CLAIM</div>
        </div>
      </div>

      <div className="asset-container products-container">
        <div></div>
        {/* <h1
          className="text-center pt-5 pb-5 asset-header-title"
          style={{ fontFamily: "Clash Display Medium", color: "white", textDecoration: "underline #C44DD6" }}
        >
          My Assets
        </h1> */}

        <h2 className='text-center mb-5 pb-1' style={{ fontFamily: "Clash Display Medium", color: "#fff", margin: "auto" }}>MY ASSETS</h2>

        <div>
          <div className="row p-4">
            {pending && (
              <div className="loading">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#D19CFB"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            )}
            {!pending && assets.length > 0
              ? assets.map((i) => (
                <div className="col-md-4 d-flex justify-content-center">
                  <Link
                    to={`/assets/${i.rawMetadata.name.replace(" ", "_")}`}
                  >
                    <div className="asset-card pb-5 mb-5">
                      <img src={i.rawMetadata.image} className="asset-img" />
                      <p
                        style={{
                          color: "white",
                          fontFamily: "Clash Display Medium",
                        }}
                        className="mt-2 asset-title"
                      >
                        {i.rawMetadata.name}
                      </p>
                    </div>
                  </Link>
                </div>

              ))
              : !pending && walletAddress ? <h1 style={{ color: "white", textAlign: "center" }}>No NFT's <Link to="/products" className="text-underling text-white" ><u>"Start Collecting"</u></Link></h1> : <h1 style={{ color: "white", textAlign: "center" }}>Connect Your Wallet</h1>}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Assets;



import React from "react";

function Terms() {
  const datas = [
    {
      title: "1. OVERVIEW",
      subtitle:
        "The XR COUTURE is in the business of sale of “Digital collectible” which are blockchain-based, cryptographic nonfungible token (“NFT”) which, together with the license rights to any associated digital works of authorship or other content, whether or not copyrighted or copyrightable, and regardless of the format in which any of the foregoing is made available (“Related Content”), comprises and is referred to herein as a “Digital Collectible”." +
        <br /> +
        "Based on your demonstrated ownership of Vibrance Splash NFT, XR COUTURE has made available to you the Provided Materials that are intended to represent, in aggregate, a set of 3D files. Your license rights granted herein with respect to any Provided Materials are conditioned on Your ownership of the Vibrance Splash NFT. Your ownership of other NFTs, including other NFTs issued by XR COUTURE, will not entitle You to any license rights with respect to Provided Materials made available for a different NFT.",
    },
    {
      title: "2. SCOPE AND USE OF THE LICENSE",
      subtitle:
        "Subject to the terms and conditions of this Agreement, XR COUTURE grants You for so long as You own the Vibrance Splash NFT a non-exclusive license to:" +
        <br /> +
        "A. Download and access the Provided Materials (the 3D Files)" +
        <br /> +
        "B. Use the 3D file solely for purposes of exercising Your license rights hereunder," +
        <br /> +
        "C. Modify the 3D object on the computer owned or controlled by You or on an Authorized Editing Platform to create User Generated Content with their Clone X Avatars, but without modifying the Original Design." +
        <br /> +
        "D. Use the Vibrance Splash NFT for virtual fitting, PFP & Artwork, and to exhibit artwork in 3D on any metaverse, AR/VR platforms.",
    },
    {
      title: "3. ACCESS TO THE NFT:",
      subtitle:
        "It is hereby acknowledged and represented by the XR COUTURE that the owners of the NFT shall have the full access of the Vibrance Splash NFT, provided materials and the associated 3D files during the ownership period." +
        <br /> +
        "It is further acknowledged by the present owner that once the ownership of the Vibrance Splash NFT is transferred, then the Present owner have to delete the Provided Materials, the Original 3D files, any duplicates made during the ownership, or any copies stored whether its offline or online.",
    },
    {
      title: "4. COVENANTS TO FOLLOW:",
      subtitle:
        "With the purchase of the Vibrance Splash NFT, the Owners gets some rights to use the NFT, apart from the rights, owners also acknowledge that there are some obligations or covenants that should be followed while using the Vibrance Splash NFT, provided material along with it or the 3D files as follows:" +
        <br /> +
        <br /> +
        "A. That, they are free to modify the NFT with their Clone X Avatars but it is strictly advised that they shall not degrade the Original design or Branding of the NFT or 3D files." +
        <br /> +
        "B. That, owners shall maintain the original visual representation of the Design of the NFT." +
        <br /> +
        "C. That, owners shall have all the rights of modifying or to create user generated content but without modifying the Original designs, including color variants, tags etc" +
        <br /> +
        "You understand that XR COUTURE will continue to further modify and develop on any Provided Materials, 3D Files and Utilities provided with the Vibrance Splash NFT from time to time.",
    },
    {
      title: "5. USER’S RIGHTS & OBLIGATIONS:",
      subtitle:
        "It is acknowledged by the Owner and agreed by the XR COUTURE that the users of the Owner’s platform can also avail the facility of the Vibrance Splash NFT. Users can modify the 3D object to use inside a game, metaverse, AR/VR platform of their own choice by strictly maintaining the original visual representation of the design." +
        <br /> +
        <br /> +
        "The users shall not have any rights in Intellectual Property, Sub-licensing, and shall not have any commercial license to the Design created by them using the Vibrance Splash NFT or 3D files associated with it." +
        <br /> +
        <br /> +
        "Users may not use any IP/Brands/Tags in connection with any business, product or service, or in any manner that may imply endorsement of any business, message, product or service, or that is likely to cause confusion or dilute, blur or tarnish such IP/Brands/Tags. All use of such IP/Brands/Tags, including any goodwill generated by such use, will inure to the benefit of XR COUTURE and its Affiliates.",
    },
    {
      title: "6. INTELLECTUAL PROPERTY RIGHTS",
      subtitle:
        "XR COUTURE retain all right, title and interest in the Provided Materials or 3D Files and all copyright or other intellectual property rights in any Provided Materials or 3D Files and any works of authorship Depicted using any Provided Materials. The users do not acquire any ownership interest in any Provided Materials under this Agreement. Without limiting the foregoing, the Provided Materials may include trade names, brands, logos, trademarks, names, likenesses, images or other personal characteristics of persons or characters (“Collectively Intellectual Property”).",
    },
    {
      title: "7. INDEMNIFICATION:",
      subtitle:
        "You will indemnify and hold harmless XR COUTURE from and against any and all claims, demands, liabilities, damages, penalties, fines, taxes, costs and expenses (including without limitation reasonable attorneys’ fees and court costs) arising out of or in connection with this Agreement or against the XR COUTURE, arising by reason of, or in connection with, the breach or alleged breach of, or falsity or inaccuracy (or alleged falsity or inaccuracy) of any representation or warranty contained in this Agreement.",
    },
    {
      title: "8. WARRANTIES:",
      subtitle:
        "XR COUTURE warrants that any provided materials or 3D Files available “As is” and expressly disclaim any and all representations of any kind, express or implied, including, without limitation, any warranties or title, non-infringement, merchantability, or fitness for a particular purpose and any warranties arising from course of performance.",
    },
    {
      title: "9. TERMINATION OF LICENSE RIGHTS:",
      subtitle:
        "The illegality or any violation of this License Agreement and if You breach any of Your obligations under this License Agreement, all of Your license rights of using NFT hereunder will immediately terminate without any requirement of notice. Your license rights will also terminate in the event You transfer the applicable Related NFT to a third party. Upon termination of Your license rights, you will immediately cease all use of any Provided Materials or 3D Files.",
    },
    {
      title: "10. GOVERNING LAW AND DISPUTE RESOLUTION:",
      subtitle:
        "This Agreement shall be governed by, and construed in accordance with, the laws of the republic of India and the parties hereby submit to the non-exclusive jurisdiction of Indian courts. Any disputes arising out of or in connection with this Agreement shall be settled by mutual discussion. If the dispute is not resolved through mutual discussion, the parties shall be bound to submit all disputes and differences howsoever arising out of and in connection with this Agreement to courts of India.",
    },
    {
      title: "11. MISCELLANEOUS:",
      subtitle:
        "This Agreement herein constitutes the entire agreement and understanding between the parties relating to the subject matter hereof, this Agreement may not be amended or modified in any way except by a written instrument signed by the concerned Parties." +
        <br /> +
        <br /> +
        "This Agreement does not, and may not be construed to, create any partnership, joint venture or agency between You and XR COUTURE. If any provision of this Agreement is found to be unlawful, void or for any reason unenforceable, that provision will be enforced to the maximum extent permitted by law and otherwise deemed severable from this Agreement and will not affect the validity and enforceability of any remaining provisions." +
        <br /> +
        <br /> +
        "This is an electronic made document, by proceeding further, you acknowledge that you have read this Agreement carefully and agree to be bound by the terms & conditions of using Vibrance Splash NFT or 3D files associated with it.",
    },
  ];

  return (
    <div>
      <h4
        style={{
          fontFamily: "Clash Display Medium",
          color: "rgb(151, 128, 151)",
        }}
      >
        Please Note:
      </h4>
      <h5
        style={{
          fontFamily: "Clash Display Light",
          color: "rgb(151, 128, 151)",
        }}
      >
        This is an electronic generated document, by proceeding further you
        agree that you have accepted below mention terms of this document that
        how to use the 3D files provided along with the Vibrance Splash NFT.
        <br />
        <br />
        It is acknowledged by you that if you are agreeing on behalf of another
        Person, User, Company or other Legal Entity, you represent and warrant
        that you have the authority to bind that Person, User, Company or Legal
        Entity to the terms of this License Agreement.
        <br />
        <br />
        If you do not agree to the terms and conditions of this User License
        Agreement, please do not download, copy, install, access or use any
        provided material or 3D files. This Agreement become effective from the
        day of your purchase made.
      </h5>
      {datas.map(i=>(
        <>
        <h4
        style={{
          fontFamily: "Clash Display Medium",
          color: "rgb(151, 128, 151)",
        }}
      >{i.title}</h4>
      <h5
        style={{
          fontFamily: "Clash Display Light",
          color: "rgb(151, 128, 151)",
        }}
        className="text-justify"
      >{i.subtitle}</h5>
      <br />
        </>
      ))}

      <h4
       style={{
        fontFamily: "Clash Display Medium",
        color: "rgb(151, 128, 151)",
      }}>
        Contact us on:<br/>
        NFT XRCOUTURE PVT LTD<br/>
        Email: <a href="mailto:hello@xrcouture.com">hello@xrcouture.com</a>
      </h4>
    </div>
  );
}

export default Terms;
